import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
} from '@mui/material';
import { useEffect, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { TimesheetProps } from '../../../../types/TimesheetProps';
import { ProgressReportProps } from '../../../../types/ProgressReportProps';
import { TimesheetReportProps } from '../../../../types/TimesheetReportProps';
import api from '../../../../services/api';
import DashboardTitle from '../../../../components/atoms/DashboardTitle';
import TimesheetReportsTable from '../../../../components/molecules/TimesheetReportsTable';
import ProgressReportsTable from '../../../../components/molecules/ProgressReportsTable';
import formatDate from '../../../../utils/formatDate';

const initialValues: TimesheetProps = {
  id: 0,
  comment: '',
  jobBriefId: 0,
  visitId: 0,
  dateReference: new Date(),
  createdAt: new Date(),
};

function DashboardTimesheetDetails() {
  const { id } = useParams();
  const [timesheet, setTimesheet] = useState<TimesheetProps>(initialValues);

  const timesheetCreatedDate = useMemo(
    () => formatDate(timesheet.createdAt),
    [timesheet],
  );

  const timesheetDate = useMemo(
    () => formatDate(timesheet.dateReference, true),
    [timesheet],
  );

  const filteredTimesheetReports: TimesheetReportProps[] = useMemo(() => {
    if (!timesheet.timesheetReports) return [];
    return timesheet.timesheetReports.map(timesheetReport => ({
      ...timesheetReport,
      timesheet,
    }));
  }, [timesheet]);
  const filteredProgressReports: ProgressReportProps[] = useMemo(() => {
    if (!timesheet.progressReports) return [];
    return timesheet.progressReports.map(progressReport => ({
      ...progressReport,
      timesheet,
    }));
  }, [timesheet]);
  

  const informationsToDisplay = useMemo(
    () => [
      {
        label: 'Created date',
        value: timesheetCreatedDate,
      },
      {
        label: 'Date',
        value: timesheetDate,
      },
      {
        label: 'Visit',
        value: timesheet.visit ? formatDate(timesheet.visit.startDate) : '',
      },
      {
        label: 'Building',
        value:
          timesheet.visit && timesheet.visit.building
            ? timesheet.visit.building.name
            : '',
      },
      {
        label: 'Supervisor',
        value:
          timesheet.supervisor && timesheet.supervisor.firstName
            ? `${timesheet.supervisor.referenceNumber} - ${timesheet.supervisor.firstName} ${timesheet.supervisor.lastName}`
            : '',
      },
      {
        label: 'Team',
        value:
          timesheet.visit && timesheet.visit.team
            ? timesheet.visit.team.name
            : 'Multiple Teams',
      },
    ],
    [timesheet, timesheetCreatedDate, timesheetDate],
  );

  const getTimesheetById = async (timesheetId: number) => {
    const response = await api.get(`/timesheet/${timesheetId}`);
    if (response.status === 200) {
      setTimesheet(response.data.timesheet);
    }
  };

  useEffect(() => {
    if (!id) return;
    getTimesheetById(parseInt(id, 10));
  }, [id]);

  return (
    <>
      <DashboardTitle title='Timesheet Details' />
      <Card sx={{ mb: 3 }}>
        <CardHeader
          title={`Timesheet ${timesheet.id}`}
          subheader={`Here you can see all details about the Timesheet from date ${timesheetDate}`}
        />
        <CardContent sx={{ display: 'flex' }}>
          <Box sx={{ width: '100%' }}>
            {informationsToDisplay.map(timesheetInfo => (
              <>
                <Typography
                  key={`item-${timesheetInfo.label}`}
                  variant='h5'
                  mt={2}
                  display='flex'
                  alignItems='center'
                >
                  {`${timesheetInfo.label}: `}
                  <Typography
                    variant='h6'
                    fontWeight={300}
                    sx={{ ml: 1 }}
                  >
                    {timesheetInfo.value}
                  </Typography>
                </Typography>
                <Divider />
              </>
            ))}
            <Typography
              variant='h6'
              mt={2}
            >
              Comments
              <Typography
                variant='subtitle1'
                fontWeight={200}
                sx={{ width: '100%', overflowWrap: 'break-word' }}
              >
                {timesheet.comment || 'No comments added!'}
              </Typography>
            </Typography>
          </Box>
        </CardContent>
      </Card>
      {timesheet.timesheetReports && timesheet.timesheetReports.length > 0 && (
        <>
          <Typography
            variant='h5'
            sx={{ mb: 2 }}
          >
            Reported timesheets
          </Typography>
          <TimesheetReportsTable timesheetReports={filteredTimesheetReports} />
        </>
      )}
      {timesheet.progressReports && timesheet.progressReports.length > 0 && (
        <>
          <Typography
            variant='h5'
            sx={{ mt: 4, mb: 2 }}
          >
            Reported progress
          </Typography>
          <ProgressReportsTable
            progressReports={filteredProgressReports}
            showComments={false}
          />
        </>
      )}
    </>
  );
}

export default DashboardTimesheetDetails;
