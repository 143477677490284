import React from 'react';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import formatDate from '../../../utils/formatDate';
import DashboardTable from '../../atoms/DashboardTable';

type Supervisor = {
  referenceNumber: string;
  firstName: string;
  lastName: string;
};

interface SingleTimesheetProductivityReport {
  team?: string;
  date: Date;
  productivity: string;
  timesheetId: number;
  visitId: number;
  supervisor: Supervisor;
}


interface ProductivityTableProps {
  reports: SingleTimesheetProductivityReport[];
}

const columns: GridColDef[] = [
  {
    field: 'date',
    headerName: 'Reference date',
    type: 'string',
    width: 250,
    align: 'center',
    headerAlign: 'center',
    filterable: false,
    renderCell: (params: GridRenderCellParams) => {
      const { row } = params;
      return formatDate(row.date, true);
    },
  },
  {
    field: 'supervisor',
    headerName: 'Supervisor',
    flex: 1,
    filterable: false,
    sortable: false,
    renderCell: (params: GridRenderCellParams<SingleTimesheetProductivityReport>) => {
      const { firstName, lastName, referenceNumber } = params.row.supervisor
      
      return `${referenceNumber} - ${firstName} ${lastName}`;
    },
  },
  {
    field: 'team',
    headerName: 'Team Name',
    flex: 1,
    filterable: false,
    sortable: false,
  },
  {
    field: 'productivity',
    headerName: 'Productivity',
    width: 200,
    align: 'center',
    headerAlign: 'center',
    filterable: false,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => {
      const { productivity } = params.row;
      if (!productivity || productivity === '-') return 'N/A';
      return `${productivity}%`;
    },
  },
  {
    field: 'timesheetsId',
    headerName: 'Timesheet',
    align: 'center',
    headerAlign: 'center',
    flex: 1,
    filterable: false,
    sortable: false,
    renderCell: (params: GridRenderCellParams<SingleTimesheetProductivityReport>) => {
      const id = params.row.timesheetId;

      return  (
        <a
          style={{ color: '#263262', textDecoration: 'none' }}
            href={`/dashboard/timesheets/${id}`}
            >{`timesheet ${id}`}</a>
      )     
    },
  },
  {
    field: 'visitsId',
    headerName: 'Visits',
    align: 'center',
    headerAlign: 'center',
    flex: 1,
    filterable: false,
    sortable: false,
    renderCell: (params: GridRenderCellParams<SingleTimesheetProductivityReport>) => {
      const id = params.row.visitId;

      return  <a
      style={{ color: '#263262', textDecoration: 'none' }}
      href={`/dashboard/visits/${id}`}
    >{`visit ${id}`}</a>
    },
  },
];

export default function ProductivityTable({ reports }: ProductivityTableProps) {
  return (
    <DashboardTable
      getRowId={row => row.timesheetId }
      sortModel={[{ field: 'date', sort: 'desc' }]}
      columns={columns}
      rows={reports}
      slots={{
        toolbar: () => null,
      }}
    />
  );
}
