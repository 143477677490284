import {
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
} from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { toast } from 'material-react-toastify';
import { Chip } from '@mui/material';
import { ROLES } from '../../../utils/constants';
import api from '../../../services/api';
import { useUser } from '../../../contexts/UserContext';
import { useDialog } from '../../../contexts/DialogContext';
import UserDetailsDialog from '../UserDetailsDialog';
import { UserProps } from '../../../types/UserProps';
import DashboardTable from '../../atoms/DashboardTable';

interface UsersTableProps {
  getAllUsers?: Function;
  users: UserProps[];
}

function UsersTable({ getAllUsers, users }: UsersTableProps) {
  const { showDialog } = useDialog();
  const { user } = useUser();
  const isSupervisor =
    user?.role === 'supervisor' || user?.role === 'contractor';

  const handleDeactivateUser = async (params: GridRowParams) => {
    const { row } = params;
    toast.dismiss();

    const response = await api.put('/user/activeStatus', {
      id: row.id,
      isActive: !row.isActive,
    });

    if (response.status === 200) {
      toast.success(
        `${row.isActive ? 'Deactivated' : 'Activated'} user successfully!`,
      );
      if (getAllUsers) getAllUsers();
    }
  };

  const handleDeleteUser = async (id: number) => {
    toast.dismiss();

    const response = await api.delete('/user', {
      data: {
        id,
      },
    });

    if (response.status === 200) {
      toast.success(`User deleted successfully!`);
      if (getAllUsers) getAllUsers();
    }
  };

  const showDeleteButton = (params: GridRowParams) => (
    <GridActionsCellItem
      sx={{ color: 'error.main' }}
      icon={<DeleteForeverIcon sx={{ color: 'error.main' }} />}
      label='Delete'
      onClick={() => handleDeleteUser(params.row.id)}
      showInMenu
    />
  );

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      type: 'string',
      maxWidth: 80,
      align: 'center',
      headerAlign: 'center',
      filterable: false,
    },
    {
      field: 'referenceNumber',
      headerName: 'Ref. Number',
      type: 'string',
      width: 120,
      align: 'center',
      headerAlign: 'center',
      filterable: true,
    },
    /* {
      field: 'username',
      headerName: 'Username',
      flex: 1,
      filterable: false,
    }, */
    {
      field: 'firstName',
      headerName: 'Name',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => params.row.firstName,
    },
    {
      field: 'lastName',
      headerName: 'Surname',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => params.row.lastName,
    },
    {
      field: 'teamId',
      headerName: 'Team',
      flex: 1,
      renderCell: ({ row }: GridRenderCellParams) =>
        row.team ? row.team.name : 'Not assigned',
    },
    ...(isSupervisor
      ? [] : [
          {
            field: 'email',
            headerName: 'Email',
            flex: 1,
            filterable: false,
            renderCell: ({ row }: GridRenderCellParams) =>
              row.role === 'operative' ? '' : row.email,
          },
          {
            field: 'phone',
            headerName: 'Phone',
            width: 120,
            filterable: false,
            renderCell: ({ row }: GridRenderCellParams) =>
              row.role === 'operative' ? '' : row.phone,
          },
        ]),
    {
      field: 'role',
      headerName: 'Role',
      width: 120,
      align: 'center',
      headerAlign: 'left',
      type: 'singleSelect',
      valueOptions: ROLES,
    },
    {
      field: 'isActive',
      headerName: 'Status',
      headerAlign: 'left',
      align: 'center',
      width: 120,
      type: 'singleSelect',
      valueOptions: ['Active', 'Deactivated'],
      /* renderCell: (params: GridRenderCellParams) => {
        const cell = params.row.isActive ? 'Active' : 'Deactivated';
        return cell;
      }, */
      renderCell: (params: GridRenderCellParams) => (
        <Chip
          label={params.row.isActive ? 'Active' : 'Deactivated'}
          color={params.row.isActive ? 'success' : 'error'}
        />
      ),
    },
  ];

  if (user?.role === 'admin')
    columns.push({
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      align: 'center',
      headerAlign: 'center',
      width: 80,
      filterable: false,
      getActions: (params: GridRowParams) => [
        ...(params.row.id !== user.id
          ? [
              <GridActionsCellItem
                icon={<EditIcon />}
                label='Edit'
                onClick={() =>
                  showDialog({
                    componentToRender: (
                      <UserDetailsDialog
                        getAllUsers={getAllUsers}
                        user={params.row}
                      />
                    ),
                  })
                }
              />,
              <GridActionsCellItem
                icon={
                  params.row.isActive ? (
                    <PersonOffIcon />
                  ) : (
                    <PowerSettingsNewIcon />
                  )
                }
                label={params.row.isActive ? 'Deactivate' : 'Activate'}
                onClick={() => handleDeactivateUser(params)}
                showInMenu
              />,
              !params.row.isActive ? showDeleteButton(params) : <div />,
            ]
          : [
              <GridActionsCellItem
                icon={<EditIcon />}
                label='Edit'
                onClick={() =>
                  showDialog({
                    componentToRender: (
                      <UserDetailsDialog
                        getAllUsers={getAllUsers}
                        user={params.row}
                      />
                    ),
                  })
                }
              />,
            ]),
      ],
    });

  return (
    <DashboardTable
      getRowId={User => User.id}
      columns={columns}
      rows={users}
    />
  );
}

export default UsersTable;
