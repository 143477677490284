import { useState, useEffect, useMemo } from 'react';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { toast } from 'material-react-toastify';
import {
  startOfWeek,
  endOfWeek,
  eachDayOfInterval,
  format,
  isSameDay,
} from 'date-fns';
import api from '../../../services/api';
import DashboardTable from '../../atoms/DashboardTable';

type Supervisor = {
  referenceNumber: string;
  firstName: string;
  lastName: string;
};
type ProductivityReport = {
  supervisor: Supervisor;
  dates: {
      dateReference: Date;
      productivity: string;        
  }[]
};

export default function ProductivityTable() {
  const [report, setReport] = useState<ProductivityReport[]>([]);

  const columns = useMemo(() => {
    const currentDate = new Date();
    const currentWeekStart = startOfWeek(currentDate, { weekStartsOn: 1 });
    const currentWeekEnd = endOfWeek(currentDate, { weekStartsOn: 1 });
    const weekDays = eachDayOfInterval({
      start: currentWeekStart,
      end: currentWeekEnd,
    });

    return [
      {
        field: 'supervisor',
        headerName: 'Supervisor',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        editable: false,
        renderCell: (params: GridRenderCellParams<ProductivityReport>) => {
            const { supervisor } = params.row;
          
            const { referenceNumber, firstName, lastName } = supervisor;

            return `${referenceNumber} - ${firstName} ${lastName}`;                  
        },
      },
      ...weekDays.map(day => ({
        field: day.toISOString(),
        headerName: format(day, 'cccc (dd-MMM)'),
        headerAlign: 'center',
        align: 'center',
        width: 200,
        sortable: false,
        editable: false,
        renderCell: (params: GridRenderCellParams<ProductivityReport>) => {
          const { dates } = params.row;

          const date = dates.find(({ dateReference }) => isSameDay(new Date(dateReference), day));

          if (!date) return '';

          const { productivity } = date
        
          if (!productivity || String(productivity) === '-') return 'N/A';
          
          return `${productivity}%`;
        
        },
      })),
    ] as GridColDef[];
  }, []);

  useEffect(() => {
    async function fetchReports() {
      try {
        const response = await api.get('/productivity/weekly-report');
        const { status, data } = response;
        if (status !== 200) {
          throw new Error(`Status from api is invalid: ${status}`);
        }
        const { productivityReport } = data;
        setReport(productivityReport);
      } catch (error) {
        toast.error('Failed to fetch productivity reports');
        console.error(error);
      }
    }

    fetchReports();
  }, []);

  return (
    <DashboardTable
      getRowId={row => row.supervisor.referenceNumber}
      sortModel={[{ field: 'date', sort: 'desc' }]}
      columns={columns}
      rows={report}
      slots={{
        toolbar: () => null,
      }}
    />
  );
}
