import { useMemo, useCallback } from 'react';
import {
  GridColDef,
  GridRenderCellParams,
  DataGridProps,
} from '@mui/x-data-grid';
import { toast } from 'material-react-toastify';
import api from '../../../services/api';
import { TimesheetReportProps } from '../../../types/TimesheetReportProps';
import convertDecimalToTime from '../../../utils/convertDecimalToTime';
import formatDate from '../../../utils/formatDate';
import getTimeBetweenTimeStrings from '../../../utils/getTimeBetweenTimeStrings';
import roundTimeToQuarters from '../../../utils/roundTimeToQuarters';
import getMinimumBreakHours from '../../../utils/getMinimumHoursBreak';
import DashboardTable from '../../atoms/DashboardTable';
import ExportTableToolbar from '../../atoms/ExportTableToolbar';
import useSupervisors from '../../../hooks/useSupervisors';

interface TimesheetReportTableProps {
  timesheetReports: TimesheetReportProps[];
}

function TimesheetReportTable({ timesheetReports }: TimesheetReportTableProps) {
  const { supervisors } = useSupervisors();

  const removeSecondsFromTime = useCallback(
    (time: string) => time.substring(0, time.lastIndexOf(':')),
    [],
  );

  const processRowUpdate = useCallback(
    async (
      updatedRow: Record<string, unknown>,
      originalRow: Record<string, unknown>,
    ) => {
      const { startTime, finishTime, dayOff } = updatedRow;

      /* Manage enabling dayOff */
      if (dayOff) {
        const newRow = {
          ...updatedRow,
          startTime: null,
          finishTime: null,
          hours: null,
          breakHours: null,
          total: null,
        };

        const result = await api.put(
          `/timesheetReport/${originalRow.id}`,
          newRow,
        );
        if (!result || result.status !== 200) return originalRow;
        toast.success('Row updated successfully');
        return newRow;
      }

      /* Manage disabling dayOff */
      if (originalRow.dayOff && !dayOff) {
        const DEFAULT_START_TIME = '10:00:00';
        const DEFAULT_FINISH_TIME = '12:00:00';

        const newRow = {
          ...updatedRow,
          startTime: DEFAULT_START_TIME,
          finishTime: DEFAULT_FINISH_TIME,
          hours: 2,
          breakHours: 0,
          total: 2,
        };

        const result = await api.put(
          `/timesheetReport/${originalRow.id}`,
          newRow,
        );
        if (!result || result.status !== 200) return originalRow;
        toast.success('Row updated successfully');
        return newRow;
      }

      /* Manage start time and finish time updates */
      // Validation: Check if the input matches the "hh:mm:ss" format
      const timeFormat = /^\d{2}:\d{2}:\d{2}$/;
      if (
        !startTime ||
        !finishTime ||
        typeof startTime !== 'string' ||
        typeof finishTime !== 'string' ||
        !timeFormat.test(startTime) ||
        !timeFormat.test(finishTime)
      ) {
        return originalRow;
      }

      const startHour = Number((startTime as string).replace(/\D/g, ''));
      const finishHour = Number((finishTime as string).replace(/\D/g, ''));
      if (
        Number.isNaN(startHour) ||
        Number.isNaN(finishHour) ||
        startHour > finishHour ||
        startHour === finishHour
      ) {
        return originalRow;
      }

      // Removes seconds from time
      const startTimeWoSeconds = removeSecondsFromTime(startTime as string);
      const finishTimeWoSeconds = removeSecondsFromTime(finishTime as string);

      // Rounds time to quarters
      const formattedStartTime = roundTimeToQuarters(startTimeWoSeconds);
      const formattedFinishTime = roundTimeToQuarters(finishTimeWoSeconds);

      const { decimal: hours } = getTimeBetweenTimeStrings(
        formattedStartTime,
        formattedFinishTime,
      );
      const breakHours = getMinimumBreakHours(hours);
      const total = Number(Number(hours.toFixed(2)) - breakHours).toFixed(1);

      const newRow = {
          ...updatedRow,
          startTime: `${formattedStartTime}:00`,
          finishTime: `${formattedFinishTime}:00`,
          hours,
          total,
          breakHours,
      };
    
      const result = await api.put(
          `/timesheetReport/${originalRow.id}`,
          newRow,
      );
        
      if (!result || result.status !== 200) {
          toast.error(result.data);
          return originalRow;
      }
  
      toast.success('Row updated successfully');
      return newRow;
    },
    [removeSecondsFromTime],
  );

  const handleProcessRowUpdateError = useCallback((error: Error) => {
    toast.error(error.message || 'Could not update the row. Please try again.');
  }, []);

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'userId',
        headerName: 'Operative/Supervisor',
        align: 'center',
        headerAlign: 'center',
        flex: 1,
        filterable: true,
        renderCell: (params: GridRenderCellParams) => {
          const { referenceNumber, firstName, lastName } = params.row.user;
          const isSupervisor = supervisors.some(
            supervisor => supervisor.id === params.row.userId,
          );
          const displayName = `${referenceNumber} - ${firstName} ${lastName}`;
          return isSupervisor ? `${displayName} (Supervisor)` : displayName;
        },
      },
      {
        field: 'serviceName',
        headerName: 'Service',
        align: 'center',
        headerAlign: 'center',
        flex: 1,
        filterable: true,
        renderCell: (params: GridRenderCellParams) => {
          const { serviceName } = params.row.timesheet.visit;
          return serviceName;
        },
      },
      {
        field: 'startTime',
        headerName: 'Start time',
        align: 'center',
        headerAlign: 'center',
        width: 100,
        filterable: false,
        editable: true,
        renderCell: (params: GridRenderCellParams) => {
          const { dayOff, startTime } = params.row;
          return dayOff ? '' : removeSecondsFromTime(startTime);
        },
      },
      {
        field: 'finishTime',
        headerName: 'Finish time',
        align: 'center',
        headerAlign: 'center',
        width: 100,
        filterable: false,
        editable: true,
        renderCell: (params: GridRenderCellParams) => {
          const { dayOff, finishTime } = params.row;
          return dayOff ? '' : removeSecondsFromTime(finishTime);
        },
      },
      {
        field: 'hours',
        headerName: 'Hours',
        align: 'center',
        headerAlign: 'center',
        width: 100,
        filterable: false,
        renderCell: (params: GridRenderCellParams) => {
          const { dayOff, hours } = params.row;
          return dayOff ? '' : convertDecimalToTime(hours);
        },
      },
      {
        field: 'breakHours',
        headerName: 'Break hours',
        align: 'center',
        headerAlign: 'center',
        width: 100,
        filterable: false,
        renderCell: (params: GridRenderCellParams) => {
          const { dayOff, breakHours } = params.row;
          return dayOff ? '' : convertDecimalToTime(breakHours);
        },
      },

      {
        field: 'total',
        headerName: 'Total',
        align: 'center',
        headerAlign: 'center',
        width: 100,
        filterable: false,
      },
      {
        field: 'dayOff',
        type: 'boolean',
        headerName: 'Day off',
        align: 'center',
        headerAlign: 'center',
        width: 100,
        filterable: true,
        editable: true,
      },
      {
        field: 'buildingId',
        headerName: 'Building',
        align: 'center',
        headerAlign: 'center',
        width: 120,
        filterable: true,
        renderCell: (params: GridRenderCellParams) => {
          const { name } = params.row.timesheet.visit.building;
          return name;
        },
      },
      {
        field: 'teamId',
        headerName: 'Team',
        align: 'center',
        headerAlign: 'center',
        width: 140,
        filterable: true,
        renderCell: (params: GridRenderCellParams) =>
          params.row.timesheet.visit.team
            ? params.row.timesheet.visit.team.name
            : params.row.user.team.name,
      },
      {
        field: 'dateReference',
        headerName: 'Timesheet date',
        type: 'date',
        align: 'center',
        headerAlign: 'center',
        width: 144,
        filterable: true,
        renderCell: (params: GridRenderCellParams) => {
          const { dateReference } = params.row.timesheet;
          return formatDate(dateReference, true);
        },
      },
    ],
    [supervisors, removeSecondsFromTime],
  );

  return (
    <DashboardTable
      getRowId={Visit => Visit.id}
      columns={columns}
      rows={timesheetReports}
      slots={{
        toolbar: ExportTableToolbar as DataGridProps['slots'] as any,
      }}
      processRowUpdate={processRowUpdate}
      onProcessRowUpdateError={handleProcessRowUpdateError}
    />
  );
}

export default TimesheetReportTable;
