import {
  Autocomplete,
  Box,
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { toast } from 'material-react-toastify';
import { useEffect, useState, useMemo, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import PDFButton from '../../../../components/atoms/PDFButton';
import VisitsList from '../../../../components/atoms/VisitsList';
import TimesheetsTable from '../../../../components/molecules/TimesheetsTable';
import Details from '../../../../components/organisms/Details';
import JobBriefPDF from '../../../../components/pdf/JobBriefPDF';
import { useUser } from '../../../../contexts/UserContext';
import useQuotes from '../../../../hooks/useQuotes';
import useSupervisors from '../../../../hooks/useSupervisors';
import api from '../../../../services/api';
import { JobBriefProps } from '../../../../types/JobBriefProps';
import useTimesheets from '../../../../hooks/useTimesheets';

interface ITask {
  id: number;
  name: string;
  time: number;
  price: number;
  units: number;
  createdAt: string;
  updatedAt: string;
  visitId: number;
}

interface IProgressReport {
  completedUnits: number;
  id: number;
  timesheetId: number;
  visitTaskId: number;
}

function JobBriefDetails() {
  /* CUSTOM HOOKS */
  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));
  const isTablet = useMediaQuery(useTheme().breakpoints.down('lg'));

  const { id } = useParams();
  const { quotes } = useQuotes({ onlyContracts: true });
  const { supervisors } = useSupervisors();
  const { user } = useUser();
  const { timesheets } = useTimesheets({ bySupervisor: false });

  /* STATES */
  /* 
    FYI 
    - jobBrief: the job brief data from the database
    - currentJobBrief: the job brief data that is being edited by the user
  */
  const [jobBrief, setJobBrief] = useState({} as JobBriefProps);
  const [currentJobBrief, setCurrentJobBrief] = useState({} as JobBriefProps);
  const [tasks, setTasks] = useState<ITask[]>([]);
  const [progressReports, setProgressReports] = useState<IProgressReport[]>([]);
  const [jobBriefSummary, setJobBriefSummary] = useState<
    {
      serviceName: string;
      completedUnits: number;
    }[]
  >([]);

  /* MEMO VALUES */
  const isSupervisor = useMemo(
    () => user?.role === 'supervisor' || user?.role === 'contractor',
    [user],
  );

  const supervisorsNotInJobBrief = useMemo(() => {
    if (!jobBrief.supervisorsId) return supervisors;
    return supervisors.filter(
      supervisor => !jobBrief.supervisorsId.includes(supervisor.id),
    );
  }, [jobBrief, supervisors]);

  const canSubmit = useMemo(
    () => JSON.stringify(jobBrief) !== JSON.stringify(currentJobBrief),
    [jobBrief, currentJobBrief],
  );

  const quoteToFind = useMemo(
    () => quotes.find(quote => quote.id === currentJobBrief.quoteId),
    [quotes, currentJobBrief],
  );

  const informationsToDisplay = useMemo(
    () => [
      {
        label: 'Building',
        value: currentJobBrief.building?.name,
      },
      {
        label: 'Building address',
        value: currentJobBrief.building?.address,
      },
      {
        label: 'Supervisors',
        value: currentJobBrief.supervisors
          ?.map(supervisor => `${supervisor.firstName} ${supervisor.lastName}`)
          .join(', '),
      },
      {
        label: 'Status',
        value: currentJobBrief.status,
      },
    ],
    [currentJobBrief],
  );

  const getProgressReports = useCallback(
    (jobBriefId: number) => {
      const filteredTimesheetsPerJobBrief = timesheets.filter(
        timesheet => timesheet.jobBriefId === jobBriefId,
      );
      const filteredProgressReports = filteredTimesheetsPerJobBrief
        .map(timesheet => timesheet.progressReports || [])
        .flat() as IProgressReport[];

      setProgressReports(filteredProgressReports);
    },
    [timesheets],
  );

  const getTasks = useCallback((jobBriefData: JobBriefProps) => {
    if (!jobBriefData.visits) {
      setTasks([] as ITask[]);
      return;
    }
    const newTasks = jobBriefData.visits
      .map(visit => visit.visitTasks as ITask[])
      .flat();
    setTasks(newTasks || []);
  }, []);

  const getJobBriefById = useCallback(
    async (jobBriefId: number) => {
      const response = await api.get(`/jobBrief/${jobBriefId}`);
      if (response.status !== 200) {
        toast.error('Failed to retrieve job brief details!');
        return;
      }

      const jobBriefData = response.data.jobBrief as JobBriefProps;

      setJobBrief(jobBriefData);
      setCurrentJobBrief(jobBriefData);

      const filteredTimesheetsPerJobBrief = timesheets.filter(
        timesheet => timesheet.jobBriefId === jobBriefId,
      );
      const timesheetsProgressReports = filteredTimesheetsPerJobBrief
        .map(timesheet => timesheet.progressReports || [])
        .flat() as IProgressReport[];

      setProgressReports(timesheetsProgressReports);

      const summary = Array.from(
        jobBriefData.visits!.reduce(
          (serviceNames, visit) =>
            serviceNames.set(
              visit.serviceName,
              (serviceNames.get(visit.serviceName) || 0) +
                +(visit.completedUnits || 0),
            ),
          new Map(),
        ),
        ([serviceName, completedUnits]) => ({
          serviceName,
          completedUnits,
        }),
      );

      const newTasks = !jobBriefData.visits
        ? []
        : jobBriefData.visits.map(visit => visit.visitTasks as ITask[]).flat();

      setTasks(newTasks || []);
      setJobBriefSummary(summary);
    },
    [timesheets],
  );

  const handleSubmit = async () => {
    const response = await api.put(`/jobBrief/${id}`, currentJobBrief);
    if (response.status === 200) {
      toast.success('Job Brief saved successfully!');

      setJobBrief(response.data.jobBrief);
      setCurrentJobBrief(response.data.jobBrief);
      return;
    }
    toast.error('Something went wrong!');
  };

  useEffect(() => {
    if (!id) return;
    getJobBriefById(parseInt(id, 10));
  }, [id, getJobBriefById]);

  useEffect(() => {
    if (!currentJobBrief.id) return;
    getProgressReports(currentJobBrief.id);
    getTasks(currentJobBrief);
  }, [currentJobBrief, getProgressReports, getTasks]);

  return (
    <>
      <Details
        title='Job Brief Detail'
        name='See details of this Job Brief'
        subTitle={`Job Brief ${currentJobBrief.referenceNumber}`}
      >
        <Box
          sx={{ width: '100%', display: isMobile ? 'block' : 'flex', pb: 2 }}
        >
          <Box sx={isMobile ? {} : { minWidth: '50%', maxWidth: '50%' }}>
            {informationsToDisplay.map(clientInfo => (
              <>
                <Typography
                  variant='h5'
                  mt={2}
                  display='flex'
                  alignItems='center'
                >
                  {`${clientInfo.label}: `}
                  <Typography
                    variant='h6'
                    fontWeight={300}
                    sx={{ ml: 1 }}
                  >
                    {clientInfo.value}
                  </Typography>
                </Typography>
                <Divider />
              </>
            ))}
            <Typography
              variant='h6'
              mt={2}
              display='flex'
              alignItems='left'
              sx={{ display: 'flex', flexDirection: 'column' }}
            >
              Comments
              <Typography
                variant='subtitle1'
                fontWeight={200}
                sx={{
                  width: '100%',
                  overflowWrap: 'break-word',
                }}
              >
                {currentJobBrief.comment || 'No comments added'}
              </Typography>
            </Typography>
          </Box>
          <Box
            sx={{
              width: '100%',
              mb: 4,
              height: 'auto',
              mt: isMobile ? 2 : 0,
            }}
          >
            <VisitsList
              title='Visits related to this job brief'
              visits={currentJobBrief.visits || []}
            />
            {jobBriefSummary.length > 0 && (
              <Box
                sx={{
                  ml: isMobile ? 2 : 4,
                  mt: 2,
                  overflowY: 'scroll',
                  maxHeight: '300px',
                }}
              >
                <Typography
                  variant='h6'
                  sx={{ mb: isMobile ? 2 : 0 }}
                >
                  Job Brief Summary:
                </Typography>
                <Grid
                  container
                  direction='row'
                  alignItems='center'
                  columns={5}
                  spacing={isMobile ? 1 : 3}
                  style={{
                    width: (() => {
                      if (isMobile) return '190%';
                      if (isTablet) return '140%';
                      return '100%';
                    })(),
                  }}
                >
                  <Grid
                    item
                    xs={2}
                  />
                  <Grid
                    item
                    xs={1}
                  >
                    <Typography
                      fontWeight='bold'
                      variant='body2'
                    >
                      Contracted
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={1}
                  >
                    <Typography
                      fontWeight='bold'
                      variant='body2'
                    >
                      Completed
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={1}
                  >
                    <Typography
                      fontWeight='bold'
                      variant='body2'
                    >
                      Remaining
                    </Typography>
                  </Grid>
                  {tasks?.map(task => {
                    const completed = progressReports.reduce((sum, report) => {
                      if (report.visitTaskId === task.id) {
                        return sum + report.completedUnits;
                      }
                      return sum;
                    }, 0);

                    const remaining = task.units - completed;

                    return (
                      <>
                        <Grid
                          item
                          xs={2}
                        >
                          <Typography
                            variant={isMobile ? 'subtitle2' : 'body1'}
                          >
                            {task.name}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={1}
                        >
                          <Typography variant='body2'>{task.units}</Typography>
                        </Grid>
                        <Grid
                          item
                          xs={1}
                        >
                          <Typography variant='body2'>{completed}</Typography>
                        </Grid>
                        <Grid
                          item
                          xs={1}
                        >
                          <Typography
                            variant='body2'
                            {...(remaining < 0
                              ? { color: 'error', sx: { fontWeight: 600 } }
                              : {})}
                          >
                            {remaining}
                          </Typography>
                        </Grid>
                      </>
                    );
                  })}
                </Grid>
              </Box>
            )}
          </Box>
        </Box>
        <Box
          sx={
            isMobile
              ? {}
              : {
                  width: '50%',
                  mt: 4,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }
          }
        >
          {user?.role === 'admin' && (
            <Autocomplete
              multiple
              options={supervisorsNotInJobBrief || undefined}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionLabel={option =>
                `${option.firstName} ${option.lastName}`
              }
              onChange={(event, value) => {
                setCurrentJobBrief({
                  ...currentJobBrief,
                  supervisors: value,
                  supervisorsId: value.map(supervisor => supervisor.id),
                });
              }}
              value={currentJobBrief.supervisors || []}
              sx={{ mb: 6, mx: 'auto', width: '80%' }}
              renderInput={params => (
                <TextField
                  {...params}
                  variant='standard'
                  label='Supervisors to add'
                  placeholder='Add supervisors'
                />
              )}
            />
          )}
          {user?.role === 'admin' && (
            <Autocomplete
              sx={{ mb: 2, width: isMobile ? '100%' : '80%' }}
              options={quotes}
              getOptionLabel={option => option.referenceNumber}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              value={quoteToFind || null}
              renderInput={params => (
                <TextField
                  {...params}
                  label='Contract'
                  placeholder='Select a contract'
                />
              )}
              onChange={(e, value) =>
                setCurrentJobBrief({
                  ...currentJobBrief,
                  quoteId: value?.id,
                })
              }
            />
          )}
          {!isSupervisor && (
            <Button
              variant='contained'
              sx={{ my: 2 }}
              disabled={!canSubmit}
              onClick={() => handleSubmit()}
              fullWidth={isMobile}
            >
              Save
            </Button>
          )}

          {jobBrief.status === 'Closed' && (
            <PDFButton
              documentTitle='Job Brief Summary Document'
              label='Generate End of Job Document'
              component={<JobBriefPDF jobBriefId={parseInt(id!, 10)} />}
            />
          )}
        </Box>
      </Details>
      <Box sx={{ mt: 3 }}>
        {isSupervisor && (
          <>
            <Typography
              variant='h4'
              sx={{ mb: 2 }}
            >
              Timesheets
            </Typography>
            {jobBrief.timesheets && (
              <TimesheetsTable
                timesheets={jobBrief.timesheets.filter(
                  timesheet => timesheet.supervisorId === user?.id,
                )}
                getAllTimesheets={() => {
                  if (id) getJobBriefById(parseInt(id, 10));
                }}
              />
            )}
          </>
        )}
      </Box>
    </>
  );
}

export default JobBriefDetails;
