import {
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
} from '@mui/x-data-grid';
import { Edit, NotListedLocation, Visibility } from '@mui/icons-material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useNavigate } from 'react-router-dom';
import { toast } from 'material-react-toastify';
import { Chip } from '@mui/material';
import { VisitProps, VisitStatus } from '../../../types/VisitProps';
import { useDialog } from '../../../contexts/DialogContext';
import VisitDetailsDialog from '../VisitDetailsDialog';
import VisitPreviewDialog from '../VisitPreviewDialog';
import api from '../../../services/api';
import DashboardTable from '../../atoms/DashboardTable';
import formatDate from '../../../utils/formatDate';

interface VisitsTableProps {
  visits: VisitProps[];
  getAllVisits: Function;
}

function VisitsTable({ visits, getAllVisits }: VisitsTableProps) {
  const { showDialog } = useDialog();
  const navigate = useNavigate();

  const handleDeleteVisit = async (id: number) => {
    const response = await api.delete('/visit', {
      data: { id },
    });

    if (response.status === 200) {
      toast.success(`Visit deleted successfully!`);
      getAllVisits();
    }
  };

  const showDeleteVisitButton = (params: GridRowParams) =>
    params.row.status === VisitStatus.POTENTIAL ? (
      <GridActionsCellItem
        sx={{ color: 'error.main' }}
        icon={<DeleteForeverIcon sx={{ color: 'error.main' }} />}
        label='Delete'
        onClick={() => handleDeleteVisit(params.row.id)}
        showInMenu
      />
    ) : (
      <div />
    );

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      type: 'string',
      maxWidth: 80,
      align: 'center',
      headerAlign: 'center',
      filterable: false,
    },
    {
      field: 'buildingId',
      headerName: 'Building',
      flex: 1,
      filterable: true,
      renderCell: (params: GridRenderCellParams) =>
        params.row.building ? params.row.building.name : null,
    },
    {
      field: 'client',
      headerName: 'Client',
      width: 200,
      filterable: true,
      renderCell: (params: GridRenderCellParams) =>
        params.row.building && params.row.building.client
        ? params.row.building.client.reference
        : null,
    },
    {
      field: 'city',
      headerName: 'City',
      width: 200,
      filterable: true,
      renderCell: (params: GridRenderCellParams) =>
        params.row.city
    },
    {
      field: 'billing',
      headerName: 'Billing',
      width: 230,
      filterable: true,
      renderCell: (params: GridRenderCellParams) =>
        params.row.building && params.row.building.billing
          ? params.row.building.billing.companyName
          : null,
    },
    {
      field: 'serviceName',
      headerName: 'Service',
      width: 217,
      filterable: true,
    },
    {
      field: 'startDate',
      headerName: 'Start Date',
      align: 'center',
      headerAlign: 'center',
      width: 160,
      type: 'date',
      filterable: true,
      valueFormatter: params => formatDate(params),
    },
    {
      field: 'finishDate',
      headerName: 'Finish Date',
      align: 'center',
      headerAlign: 'center',
      width: 160,
      type: 'date',
      filterable: true,
      valueFormatter: params => formatDate(params),
    },
    {
      field: 'status',
      headerName: 'Status',
      headerAlign: 'left',
      align: 'center',
      width: 113,
      type: 'singleSelect',
      valueOptions: Object.values(VisitStatus),
      renderCell: (params: GridRenderCellParams) => (
        <Chip
          label={params.row.status}
          color='primary'
        />
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      align: 'center',
      headerAlign: 'center',
      width: 120,
      filterable: false,
      getActions: (params: GridRowParams) => [
        <GridActionsCellItem
          icon={<Edit />}
          label='Edit'
          onClick={() =>
            showDialog({
              componentToRender: (
                <VisitDetailsDialog
                  getAllVisits={getAllVisits}
                  visit={params.row}
                />
              ),
            })
          }
        />,
        <GridActionsCellItem
          icon={<Visibility />}
          label='Preview'
          onClick={() =>
            showDialog({
              componentToRender: (
                <VisitPreviewDialog
                  visitsList={visits}
                  currentVisit={params.row}
                />
              ),
            })
          }
        />,

        <GridActionsCellItem
          icon={<NotListedLocation />}
          label='See details'
          onClick={() => navigate(`/dashboard/visits/${params.row.id}`)}
          showInMenu
        />,
        showDeleteVisitButton(params),
      ],
    },
  ];

  return (
    <DashboardTable
      getRowId={Visit => Visit.id}
      columns={columns}
      rows={visits}
    />
  );
}

export default VisitsTable;
