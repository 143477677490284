import { ComponentProps } from 'react';
import {
  Autocomplete,
  FormControl,
  SxProps,
  TextField,
  Theme,
} from '@mui/material';

interface AutoCompleteProps
  extends Omit<ComponentProps<typeof Autocomplete>,  'options' | 'renderInput'> {
  label: string;
  options: {
    value: string | number;
    label: string;
  }[];
  sx?: SxProps<Theme>;
}

export default function AutoComplete({
  label,
  options,
  sx,
  ...autoCompleteProps
}: AutoCompleteProps) {
  return (
    <FormControl
      sx={sx}
      fullWidth
    >      
      <Autocomplete
      options={options}
      renderInput={(params) => <TextField {...params} variant='standard' label={label} />}
        id={`${label}`}
        {...autoCompleteProps}
       />
    </FormControl>
  );
}


