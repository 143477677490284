import React, { useState, FormEvent } from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  FormControl,
  Box,
  Autocomplete,
  TextField,
  Button,
} from '@mui/material';

import { useNavigate } from 'react-router-dom';
import { toast } from 'material-react-toastify';
import { AxiosError } from 'axios';
import { DriverTimesheetProps } from '../../../types/DriverTimesheetProps';
import api from '../../../services/api';
import useUsers from '../../../hooks/useUsers';
import { UserOptionProps } from '../../../types/UserProps';
import convertDecimalToTime from '../../../utils/convertDecimalToTime';
import getTimeBetweenTimeStrings from '../../../utils/getTimeBetweenTimeStrings';
import isEmpty from '../../../utils/hasEmptyProperties';

interface OccupiedTimes {
  [userName: string]: { startTime: string; finishTime: string }[];
}

const initialValues: DriverTimesheetProps = {
  id: 0,
  startTime: '',
  finishTime: '',
  hours: 0,
  vehicleReg: '',
  from: '',
  to: '',
  userId: 0,
};

function DriverTimesheetForm() {
  const navigate = useNavigate();
  const { users } = useUsers();
  const drivers = users.filter(user => user.isDriver);

  const [driverOption, setDriverOption] = useState<UserOptionProps | null>(
    null,
  );
  const [driverTimesheetData, setDriverTimesheetData] =
    useState<DriverTimesheetProps>(initialValues);

  const isTimeFilled =
    driverTimesheetData.startTime && driverTimesheetData.finishTime;

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    toast.dismiss();

    try {
      const response = await api.post('/driverTimesheet', driverTimesheetData);
      if (response.status === 200) {
        toast.success('Driver timesheet registered successfully!');
        navigate('/dashboard/driverTimesheets');
      }
    } catch (error) {
      console.error('Error:', error);
      if (error instanceof AxiosError) {
        const occupiedTimes = error.response?.data as OccupiedTimes;
        console.log('Response:', occupiedTimes);


        const message = (
            <div>
                {Object.entries(occupiedTimes).map(([userName, times]) => (
                  <>
                    <p>{userName} is occupied from:</p>
                    {times.map((time) => (
                      <p key={`${time.startTime}-${time.finishTime}`}>{time.startTime} to {time.finishTime}</p>
                    ))}
                  </>
                ))}
            </div>
        );

        toast.error(message);
      } 
    }
  };

  const canSubmit = isEmpty([
    driverTimesheetData.startTime,
    driverTimesheetData.finishTime,
    driverTimesheetData.hours,
    driverTimesheetData.vehicleReg,
    driverTimesheetData.from,
    driverTimesheetData.to,
    driverTimesheetData.userId,
  ]);

  return (
    <Card elevation={3}>
      <CardHeader
        sx={{
          bgcolor: 'secondary.main',
          color: 'white',
          px: 3,
          py: 2,
        }}
        titleTypographyProps={{ variant: 'h6', fontWeight: 'normal' }}
        title={
          <Grid
            container
            direction='row'
            alignItems='center'
            gap={1}
          >
            Add driver timesheet
          </Grid>
        }
      />
      <CardContent sx={{ p: 3 }}>
        <form onSubmit={handleSubmit}>
          <FormControl fullWidth>
            <Box sx={{ flexGrow: 1 }}>
              <Autocomplete
                options={drivers || []}
                value={driverOption! || null}
                disableClearable
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={option =>
                  `${option.referenceNumber} - ${option.firstName} ${option.lastName}`
                }
                onChange={(e, value) => {
                  if (value?.id && value.firstName) {
                    setDriverOption({
                      id: value.id,
                      referenceNumber: value.referenceNumber,
                      firstName: value.firstName,
                      lastName: value.lastName,
                    });
                    setDriverTimesheetData({
                      ...driverTimesheetData,
                      userId: value.id,
                    });
                  }
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    margin='dense'
                    variant='standard'
                    label='Driver'
                  />
                )}
              />
              {!!driverTimesheetData.userId && (
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(auto-fit, minmax(194px, 1fr))',
                    columnGap: 2,
                    alignItems: 'center',
                    width: '100%',
                    mt: 2,
                  }}
                >
                  <TextField
                    type='time'
                    InputLabelProps={{ shrink: true }}
                    sx={{ width: '100%' }}
                    margin='dense'
                    variant='standard'
                    label='Start time'
                    value={driverTimesheetData.startTime || ''}
                    onChange={e => {
                      const { decimal } = getTimeBetweenTimeStrings(
                        e.target.value,
                        driverTimesheetData.finishTime,
                      );

                      setDriverTimesheetData({
                        ...driverTimesheetData,
                        startTime: e.target.value,
                        hours: decimal,
                      });
                    }}
                  />
                  <TextField
                    type='time'
                    InputLabelProps={{ shrink: true }}
                    sx={{ width: '100%' }}
                    margin='dense'
                    variant='standard'
                    label='Finish time'
                    value={driverTimesheetData.finishTime || ''}
                    onChange={e => {
                      const { decimal } = getTimeBetweenTimeStrings(
                        driverTimesheetData.startTime,
                        e.target.value,
                      );

                      setDriverTimesheetData({
                        ...driverTimesheetData,
                        finishTime: e.target.value,
                        hours: decimal,
                      });
                    }}
                  />
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    disabled
                    sx={{ width: '100%' }}
                    margin='dense'
                    variant='standard'
                    label='Hours'
                    value={
                      isTimeFilled &&
                      convertDecimalToTime(driverTimesheetData.hours)
                    }
                  />
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    sx={{ width: '100%' }}
                    margin='dense'
                    variant='standard'
                    label='Vehicle Reg.'
                    value={driverTimesheetData.vehicleReg || ''}
                    onChange={e =>
                      setDriverTimesheetData({
                        ...driverTimesheetData,
                        vehicleReg: e.target.value,
                      })
                    }
                  />
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    sx={{ width: '100%' }}
                    margin='dense'
                    variant='standard'
                    label='From'
                    value={driverTimesheetData.from || ''}
                    onChange={e =>
                      setDriverTimesheetData({
                        ...driverTimesheetData,
                        from: e.target.value,
                      })
                    }
                  />
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    sx={{ width: '100%' }}
                    margin='dense'
                    variant='standard'
                    label='To'
                    value={driverTimesheetData.to || ''}
                    onChange={e =>
                      setDriverTimesheetData({
                        ...driverTimesheetData,
                        to: e.target.value,
                      })
                    }
                  />
                </Box>
              )}
            </Box>
            <Button
              type='submit'
              variant='contained'
              fullWidth
              disabled={!canSubmit}
              sx={{ mt: 4, mr: 4 }}
            >
              Submit
            </Button>
          </FormControl>
        </form>
      </CardContent>
    </Card>
  );
}

export default DriverTimesheetForm;
