import React, { useState } from 'react';
import {
  DialogContent,
  Button,
  Typography,
  Divider,
  Box,
  TableContainer,
  TableBody,
  TableCell,
  TableRow,
  Table,
  TableHead,
} from '@mui/material';
import { VisitProps } from '../../../types/VisitProps';
import formatDate from '../../../utils/formatDate';

interface VisitPreviewDialogProps {
  visitsList: VisitProps[];
  currentVisit: VisitProps;
}

function VisitPreviewDialog({
  visitsList,
  currentVisit,
}: VisitPreviewDialogProps) {
  const visits = [...visitsList].sort((a, b) => Number(a.id) - Number(b.id));
  const [visit, setVisit] = useState(currentVisit);

  const getNextVisit = () => {
    const index = visits.indexOf(visit);
    const nextIndex = index + 1;

    if (nextIndex < visits.length) {
      setVisit(visits[nextIndex]);
      return visits[nextIndex];
    }

    setVisit(visits[0]);
    return visits[0];
  };

  const getPreviousVisit = () => {
    const index = visits.indexOf(visit);
    const previousIndex = index - 1;

    if (previousIndex >= 0) {
      setVisit(visits[previousIndex]);
      return visitsList[previousIndex];
    }

    setVisit(visits[visits.length - 1]);
    return visits[visits.length - 1];
  };

  const informationsToDisplay = [
    {
      label: 'Visit ID',
      value: visit.id,
    },
    {
      label: 'Building',
      value: visit.building?.name,
    },
    {
      label: 'City',
      value: visit.city,
    },
    {
      label: 'Client',
      value: visit.building?.client?.reference,
    },
    {
      label: 'Service',
      value: visit.serviceName,
    },
    {
      label: 'Start date',
      value: visit.startDate ? formatDate(visit.startDate) : '',
    },
    {
      label: 'Finish date',
      value: visit.finishDate ? formatDate(visit.finishDate) : '',
    },
  ];

  return (
    <DialogContent sx={{ width: '100%', maxWidth: '450px' }}>
      {informationsToDisplay.map(visitInfo => (
        <div key={visitInfo.label}>
          <Typography
            variant='body1'
            mt={2}
            display='flex'
            alignItems='center'
          >
            {`${visitInfo.label}: `}
            <Typography
              variant='body2'
              fontWeight={300}
              sx={{ ml: 1 }}
            >
              {`${visitInfo.value || ''}`}
            </Typography>
          </Typography>
          <Divider />
        </div>
      ))}
      {visit.visitTasks && (
        <TableContainer sx={{ mt: 2 }}>
          <Table
            sx={{ width: '100%' }}
            size='small'
          >
            <TableHead>
              <TableRow>
                <TableCell>Tasks</TableCell>
                <TableCell align='left'>Units</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {visit.visitTasks.map(row => (
                <TableRow
                  key={row.id}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    cursor: 'pointer',
                  }}
                >
                  <TableCell
                    component='th'
                    scope='row'
                  >
                    {row.name}
                  </TableCell>
                  <TableCell align='left'>{row.units}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          mt: 2,
        }}
      >
        <Button
          type='button'
          sx={{ color: 'primary.main' }}
          onClick={() => getPreviousVisit()}
        >
          Prev
        </Button>
        <Button
          type='submit'
          sx={{ color: 'primary.main' }}
          onClick={() => getNextVisit()}
        >
          Next
        </Button>
      </Box>
    </DialogContent>
  );
}

export default VisitPreviewDialog;
