import {
  GridColDef,
  GridRenderCellParams,
  DataGridProps,
} from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { ProgressReportProps } from '../../../types/ProgressReportProps';
import formatDate from '../../../utils/formatDate';
import DashboardTable from '../../atoms/DashboardTable';
import ExportTableToolbar from '../../atoms/ExportTableToolbar';

interface ProgressReportTableProps {
  progressReports: ProgressReportProps[];
  showComments: boolean;
}

function ProgressReportTable({
  progressReports,
  showComments,
}: ProgressReportTableProps) {
  const columns: GridColDef[] = [
    {
      field: 'serviceName',
      headerName: 'Service',
      align: 'center',
      headerAlign: 'center',
      flex: 1,
      filterable: true,
      renderCell: (params: GridRenderCellParams) => {
        const { serviceName } = params.row.timesheet.visit;
        return serviceName;
      },
    },
    {
      field: 'visitTaskId',
      headerName: 'Task',
      align: 'center',
      headerAlign: 'center',
      flex: 1,
      filterable: true,
      renderCell: (params: GridRenderCellParams) => {
        const { name } = params.row.visitTask;
        return name;
      },
    },
    {
      field: 'completedUnits',
      headerName: 'Reported Units',
      align: 'center',
      headerAlign: 'center',
      width: 125,
      filterable: false,
      renderCell: (params: GridRenderCellParams) => {
        const { completedUnits } = params.row;
        return completedUnits;
      },
    },
    {
      field: 'comment',
      headerName: 'Comment',
      align: 'center',
      headerAlign: 'center',
      flex: 1,
      filterable: false,
      renderCell: (params: GridRenderCellParams) => {
        const { comment } = params.row.timesheet;
        return comment;
      },
    },
    {
      field: 'buildingId',
      headerName: 'Building',
      align: 'center',
      headerAlign: 'center',
      width: 120,
      filterable: true,
      renderCell: (params: GridRenderCellParams) => {
        const { name } = params.row.timesheet.visit.building;
        return name;
      },
    },
    {
      field: 'teamId',
      headerName: 'Team',
      align: 'center',
      headerAlign: 'center',
      width: 140,
      filterable: true,
      renderCell: (params: GridRenderCellParams) =>
        params.row.timesheet.visit.team
          ? params.row.timesheet.visit.team.name
          : 'Multiple Teams',
    },
    {
      field: 'createdAt',
      headerName: 'Timesheet date',
      type: 'string',
      align: 'center',
      headerAlign: 'center',
      width: 144,
      filterable: true,
      renderCell: (params: GridRenderCellParams) => {
        const { dateReference } = params.row.timesheet;
        return formatDate(dateReference, true);
      },
    },
  ];

  const [filteredColumns, setFilteredColumns] = useState(columns);

  useEffect(() => {
    if (!showComments)
      setFilteredColumns(
        filteredColumns.filter(item => item.headerName !== 'Comment'),
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DashboardTable
      getRowId={Visit => Visit.id}
      columns={filteredColumns}
      rows={progressReports}
      slots={{
        toolbar: ExportTableToolbar as DataGridProps['slots'] as any,
      }}
    />
  );
}

export default ProgressReportTable;
