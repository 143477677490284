import React, { FormEvent, useState } from 'react';
import {
  DialogContent,
  Button,
  DialogActions,
  Autocomplete,
  TextField,
} from '@mui/material';
import { toast } from 'material-react-toastify';
import { useDialog } from '../../../contexts/DialogContext';
import { VisitProps, VisitStatus } from '../../../types/VisitProps';
import api from '../../../services/api';

interface VisitDetailsDialogProps {
  getAllVisits: Function;
  visit?: VisitProps;
}

const initialValues: VisitProps = {
  id: 0,
  status: VisitStatus.POTENTIAL,
  startDate: new Date(),
  finishDate: new Date(),
  buildingId: 0,
  city: '',
};

function VisitDetailsDialog({ getAllVisits, visit }: VisitDetailsDialogProps) {
  const { toggleDialog } = useDialog();

  const [visitData, setVisitData] = useState<VisitProps>(
    visit || initialValues,
  );

  const hasUpdatedFields = visitData.status !== visit?.status;

  const canSubmit = hasUpdatedFields;

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    const response = await api.put('/visit', visitData);

    if (response.status === 200) {
      toast.success(`Visit updated sucessfully!`);
      getAllVisits();
    }

    toggleDialog();
  };

  return (
    <form onSubmit={handleSubmit}>
      <DialogContent sx={{ width: '100%', maxWidth: '450px' }}>
        <Autocomplete
          options={Object.values(VisitStatus) || []}
          value={visitData.status}
          onChange={(e, value) => {
            setVisitData({
              ...visitData,
              status: value,
            });
          }}
          fullWidth
          disableClearable
          renderInput={params => (
            <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              sx={{ mt: 1.7 }}
              margin='dense'
              variant='standard'
              label='Status'
            />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button
          type='button'
          sx={{ color: 'error.main' }}
          onClick={() => toggleDialog()}
        >
          Cancel
        </Button>
        <Button
          type='submit'
          disabled={!canSubmit}
        >
          Update
        </Button>
      </DialogActions>
    </form>
  );
}

export default VisitDetailsDialog;
