/* eslint-disable no-unused-vars */

import { BuildingProps } from './BuildingProps';
import { ServiceProps } from './ServiceProps';
import { TeamProps } from './TeamProps';
import { VisitTaskProps } from './VisitTaskProps';

/* eslint-disable no-shadow */
export enum VisitStatus {
  POTENTIAL = 'Potential',
  PROPOSED = 'Proposed',
  ACCEPTED = 'Accepted',
  ASSIGNED = 'Assigned',
  PROGRESS = 'In Progress',
  SUBMITTED = 'Submitted',
  COMPLETED = 'Completed',
}

export interface VisitTeamProps {
  id: number;
  visitId: number;
  teamId: number;
}

export interface VisitProps {
  id: number;
  status: VisitStatus;
  startDate: Date;
  finishDate: Date;
  notes?: string;
  buildingId: number;
  estimatedTime?: number;
  requiredStaff?: number;
  completedUnits?: number;
  teamId?: number;
  building?: BuildingProps;
  team?: TeamProps;
  serviceName?: string;
  quoteId?: number;
  jobBriefId?: number;
  whoCompleted?: string;
  updatedAt?: Date;
  visitTasks?: VisitTaskProps[];
  city: string;
  visitTeams?: VisitTeamProps[];
}


